import React, { PropsWithChildren, useEffect, useState } from 'react';
import { LeaveDialogState, NotificationTypes, OpenNotificationsPayload } from 'types';

type NotificationState = {
  showNotification: boolean;
  notificationType?: NotificationTypes;
  notificationTitle?: string;
  notificationMessage: string;
  onNotificationClose?: () => void;
};

interface AppContextInterface {
  loadingBar: boolean;
  showLoadingBar: () => void;
  hideLoadingBar: () => void;
  notificationState: NotificationState;
  showNotifications: (payload: OpenNotificationsPayload) => void;
  hideNotifications: () => void;
  showInfoBar: boolean;
  setShowInfoBar: React.Dispatch<React.SetStateAction<boolean>>;
  isOnline: boolean;
  leaveDialogState: LeaveDialogState;
  leaveApp: (leaveUrl: string, backTo?: string) => void;
  resetLeave: () => void;
  onLeave: () => void;
  openedHelpCenter: boolean;
  setOpenedHelpCenter: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = React.createContext({} as AppContextInterface);

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);
  const [loadingBar, setLoadingBar] = useState<boolean>(false);
  const [showInfoBar, setShowInfoBar] = useState(false);
  const [notificationState, setNotificationState] = useState<NotificationState>({
    showNotification: false,
    notificationType: undefined,
    notificationMessage: '',
    notificationTitle: '',
    onNotificationClose: undefined
  });
  const [leaveDialogState, setLeaveDialogState] = useState<LeaveDialogState>({
    outsideUrl: '',
    goBackTo: '',
    left: false,
    open: false
  });
  const [openedHelpCenter, setOpenedHelpCenter] = useState(false);

  let pingInterval: NodeJS.Timeout;

  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return function cleanup() {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  const handleConnectionChange = () => {
    const isOnline = window.navigator.onLine;
    if (isOnline) {
      pingGoogle();
      pingInterval = setInterval(() => {
        pingGoogle();
      }, 2000);
    } else {
      setIsOnline(false);
    }
  };

  const pingGoogle = () => {
    fetch('//google.com', {
      mode: 'no-cors'
    })
      .then(() => {
        setIsOnline(true);
        clearInterval(pingInterval as unknown as number);
      })
      .catch(() => setIsOnline(false));
  };

  const showLoadingBar = () => {
    setLoadingBar(true);
  };

  const hideLoadingBar = () => {
    setLoadingBar(false);
  };

  const showNotifications = (payload: OpenNotificationsPayload) => {
    setNotificationState({
      showNotification: true,
      notificationType: payload.type,
      notificationMessage: payload.message,
      onNotificationClose: payload.onClose,
      notificationTitle: payload.title ?? ''
    });
  };

  const hideNotifications = () => {
    setNotificationState({
      showNotification: false,
      notificationType: undefined,
      notificationMessage: '',
      onNotificationClose: () => {
        //comment to prevent unexpected empty function
      }
    });
  };

  const leaveApp = (leaveUrl: string, backTo?: string) => {
    setLeaveDialogState(prevState => ({
      ...prevState,
      outsideUrl: leaveUrl,
      goBackTo: backTo ?? '',
      open: true
    }));
  };

  const resetLeave = () => {
    setLeaveDialogState({
      outsideUrl: '',
      goBackTo: '',
      open: false,
      left: false
    });
  };

  const onLeave = () => {
    setLeaveDialogState(prevState => ({
      ...prevState,
      left: true
    }));
  };

  return (
    <AppContext.Provider
      value={{
        isOnline,
        showInfoBar,
        setShowInfoBar,
        loadingBar,
        showLoadingBar,
        hideLoadingBar,
        notificationState,
        showNotifications,
        hideNotifications,
        leaveDialogState,
        leaveApp,
        resetLeave,
        onLeave,
        openedHelpCenter,
        setOpenedHelpCenter
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
