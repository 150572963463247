import { ThemeOptions, createTheme } from '@mui/material';

import palette from './pallete';
import typography from './typography';
import MuiButton from './overrides/MuiButton';
import MuiIconButton from './overrides/MuiIconButton';
import MuiTextField from './overrides/MuiTextField';
import MuiFab from './overrides/MuiFab';
import MuiInputBase from './overrides/MuiInputBase';
import MuiSelect from './overrides/MuiSelect';

const defaultTheme = createTheme({
  spacing: 4,
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: MuiButton,
    },
    MuiIconButton: {
      styleOverrides: MuiIconButton,
    },
    MuiTextField: {
      styleOverrides: MuiTextField,
    },
    MuiFab: {
      styleOverrides: MuiFab,
    },
    MuiInputBase: {
      styleOverrides: MuiInputBase,
    },

    MuiSelect: {
      styleOverrides: MuiSelect,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 769,
      lg: 992,
      xl: 1200,
    },
  },
});

export const generateTheme = (theme: ThemeOptions) => {
  return createTheme({
    spacing: 4,
    palette,
    typography,
    components: {
      MuiButton: {
        styleOverrides: MuiButton,
      },
      MuiIconButton: {
        styleOverrides: MuiIconButton,
      },
      MuiTextField: {
        styleOverrides: MuiTextField,
      },
      MuiFab: {
        styleOverrides: MuiFab,
      },
      MuiInputBase: {
        styleOverrides: MuiInputBase,
      },

      MuiSelect: {
        styleOverrides: MuiSelect,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 375,
        md: 769,
        lg: 992,
        xl: 1200,
      },
    },
    ...theme,
  });
};

export default defaultTheme;
