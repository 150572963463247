import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import styles from './styles';

type Props = TextFieldProps & {
  darkBackground?: boolean;
  noShrinkClass?: boolean;
};

const ApariTextField: React.FC<Props> = props => {
  const { margin, size, variant, label, className, InputLabelProps, inputProps, disabled, ...rest } = props;
  const { classes } = styles();

  return (
    <TextField
      fullWidth
      margin={margin || 'normal'}
      size={size || 'small'}
      variant={variant || 'outlined'}
      label={label}
      disabled={disabled}
      InputLabelProps={{
        classes: {
          shrink: classes.inputLabelShrink
        },
        ...InputLabelProps
      }}
      inputProps={{
        className: clsx(classes.autofill, disabled && classes.disabledInput),
        ...inputProps
      }}
      classes={{
        root: clsx(classes.textField, disabled && classes.disableHover)
      }}
      className={clsx(className)}
      {...rest}
    />
  );
};

export default ApariTextField;
