import { LinearProgress, ThemeProvider } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { AppRouter } from 'routers';
import setupAxios from 'services/axios';
import './App.css';
import { ApariOfflineStatus, Notifications } from './components';
import { AppContext, AppProvider } from './context/AppContext';
import { CompanyThemeContext, CompanyThemeProvider } from './context/CompanyThemeContext';

function App() {
  const { loadingBar } = useContext(AppContext);
  const { customMaterialUITheme } = useContext(CompanyThemeContext);

  useEffect(() => {
    setupAxios();
  }, []);

  return (
    <ThemeProvider theme={customMaterialUITheme}>
      <div className="app-loader">{loadingBar && <LinearProgress variant="query" color="primary" />}</div>
      <ApariOfflineStatus />
      <AppRouter />
      <Notifications />
    </ThemeProvider>
  );
}

const Root = (): React.ReactElement => {
  return (
    <AppProvider>
      <CompanyThemeProvider>
        <App />
      </CompanyThemeProvider>
    </AppProvider>
  );
};

export default Root;
