import { FONTS } from 'constants/index';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(theme => ({
  accordionRoot: {
    '&::before': {
      height: 0
    }
  },
  accordionExpanded: {
    margin: '0px 0px !important'
  },
  accordionSummaryRoot: {
    paddingLeft: 20,
    paddingRight: 20,
    borderTop: '1px solid #E0E0E0',
    background: theme.palette.background.default
  },
  details: {
    display: 'block',
    whiteSpace: 'pre-wrap',
    padding: 20,
    fontSize: 16,
    fontWeight: 400,
    fontFamily: FONTS.PRIMARY_FONT,
    color: theme.palette.text.secondary,
    background: theme.palette.background.default,
    borderTop: '1px solid #E0E0E0',
    '& p': {
      margin: 0
    }
  },
  title: {
    fontSize: 16,
    fontWeight: 400,
    marginTop: 10,
    marginBottom: 10,
    fontFamily: FONTS.PRIMARY_FONT
  }
}));
