import { COLORS } from '@apari-shared/constants';
import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: COLORS.paperLight,
    main: COLORS.primary,
  },
  secondary: {
    contrastText: COLORS.apariBlack80,
    main: COLORS.paperLight,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: COLORS.apariBlack,
    secondary: COLORS.apariBlack50,
    link: colors.blue[600],
  },
  background: {
    default: COLORS.backgroundDark,
    paper: COLORS.backgroundLight,
  },
  grey: {
    300: COLORS.gray3,
    500: COLORS.gray5,
    600: COLORS.gray6,
  },
};

export default palette;
