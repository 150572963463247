import { COLORS } from '@apari-shared/constants';
import { ComponentsOverrides, Theme } from '@mui/material';

const styleOverrides: ComponentsOverrides<Theme>['MuiTextField'] = {
  root: {
    '& label': {
      color: COLORS.apariBlack60,
      fontSize: 13,
    },
    '& .MuiInput-underline.MuiInput-marginDense': {
      '& input': {
        paddingBottom: 4,
      },
    },
    '& .MuiInputBase-root div p': {
      fontSize: 14,
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      color: COLORS.apariBlack80,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '2px !important',
    },
    '& .MuiInput-underline:before': {
      borderBottomStyle: 'solid',
    },
  },
};

export default styleOverrides;
