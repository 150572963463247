import { GovAuthenticationProvider } from 'context/GovAuthenticationContext';
import { WebSocketProvider } from 'context/WebSocketContext';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ScreenRoutes } from 'types';

const WelcomeScreen = lazy(() => import('containers/pages/WelcomeScreen'));
const SignInGovScreen = lazy(() => import('containers/pages/SignInGovScreen'));
const Error = lazy(() => import('containers/pages/SignInGovScreen/Steps/Error'));

const ScreenRouter: React.FC = () => {
  return (
    <WebSocketProvider>
      <GovAuthenticationProvider>
        <Routes>
          <Route path="/:entrypointId" element={<WelcomeScreen />} />
          <Route path="/:entrypointId/authentication/*" element={<SignInGovScreen />} />
          <Route path="/authentication/*" element={<SignInGovScreen />} />
          <Route path={ScreenRoutes.ERROR} element={<Error />} />
        </Routes>
      </GovAuthenticationProvider>
    </WebSocketProvider>
  );
};

export default ScreenRouter;
