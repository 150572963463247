import React from 'react';
import { TextFieldProps } from '@mui/material';
import { ApariPasswordField } from 'components';
import { FormikContextType } from 'formik';

interface Props {
  control: string;
  formik: FormikContextType<any>;
  Icon?: React.ElementType;
  onIconPress?: () => void;
}

const ApPasswordField: React.FC<Props & TextFieldProps> = ({
  control,
  formik,
  Icon,
  onIconPress,
  ...rest
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { target } = event;
    formik.setFieldValue(control, target.value.trim());
  };

  return (
    <ApariPasswordField
      name={control}
      value={formik.values[control]}
      onChange={handleChange}
      error={formik.touched[control] && Boolean(formik.errors[control])}
      helperText={formik.touched[control] && (formik.errors[control] as string)}
      variant="outlined"
      fullWidth
      margin="dense"
      Icon={Icon}
      onIconPress={onIconPress}
      {...rest}
    />
  );
};

export default ApPasswordField;
