export type IntroductionSection = {
  title: string;
  description: string;
};

export type CompanyTheme = {
  logoUrl: string;
  favIconUrl?: string;
  pageTitle?: string;
  primaryColor?: string;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  backgroundColor?: string;
  errorColor?: string;
  successColor?: string;
  introductionSection: IntroductionSection[];
  translation?: Record<string, string>;
};

export type ApiCompanyTheme = {
  settings: CompanyTheme
}

export type LeaveDialogState = {
  outsideUrl: string;
  goBackTo: string;
  left: boolean;
  open: boolean;
};

export enum NotificationTypes {
  ERROR,
  WARNING,
  INFO,
  SUCCESS,
}

export type OpenNotificationsPayload = {
  type: NotificationTypes;
  message: string;
  title?: string;
  onClose?: () => void;
};

export enum UTM_QUERY_PARAMS {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
  GCLID = 'gclid',
  FCLID = 'fclid',
  REFERRAL_CODE = 'referral_code',
}
