import { makeStyles } from 'tss-react/mui';

type Props = {
  noWidthRestriction?: boolean;
  addHeight?: boolean;
};

export default makeStyles<Props>({ name: 'AnimatedTransition' })(
  (theme, { noWidthRestriction, addHeight }) => ({
    root: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: noWidthRestriction ? '100%' : 780,
        width: '100%',
        position: 'relative',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: addHeight ? '100%' : '',
        position: 'relative',
      },
    },
    centerContentVertically: {
      [theme.breakpoints.up('lg')]: {
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingBottom: 72,
      },
    },
  })
);
