import { Localisation } from 'utils';
import { DetermineEmptyValueType, Empty } from 'types';

class GlobalServices {
  static isDefined<T>(value: T | undefined | null): value is T {
    return value !== undefined && value !== null;
  }

  static definedValueIsEmpty<T extends number | boolean | string | { [key: string]: any } | any[]>(
    value: T | Empty
  ): value is DetermineEmptyValueType<T> {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    switch (typeof value) {
      case 'object':
        return Object.keys(value).length === 0;
      case 'string':
        return value === '';
      default:
        return false;
    }
  }

  static isEmpty<T extends number | string | { [key: string]: any } | any[]>(value: T | null | boolean | undefined): value is never {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    if (this.isDefined(value)) {
      return this.definedValueIsEmpty(value);
    }
    return true;
  }

  static responseCodesErrorHandler(data: any): any {
    return !GlobalServices.isEmpty(data) ? data : Localisation.localize('SERVER_ERROR_PROBLEM_WITH_RESOURCE');
  }

  static isValidEntrypointIdFormat = (entrypointId: string) =>
    new RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}').exec(entrypointId)?.[0];
}

export default GlobalServices;
