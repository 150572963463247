import React from 'react';
import { SvgIcon } from '@mui/material';
import theme from 'styles/theme';

type Props = {
  fillColor?: string;
};

const CheckedIcon: React.FC<Props> = ({ fillColor }) => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 0C0.447715 0 0 0.447716 0 1V23C0 23.5523 0.447716 24 1 24H23C23.5523 24 24 23.5523 24 23V1C24 0.447715 23.5523 0 23 0H1ZM13.2391 17.2634L18.8862 6.46336L17.1138 5.53664L11.9992 15.3184L6.58127 11.4481L5.41873 13.0755L11.7717 17.6137C12.0071 17.7819 12.3038 17.8398 12.5852 17.7727C12.8666 17.7055 13.1051 17.5197 13.2391 17.2634Z"
          fill={fillColor || theme.palette.primary.main}
        />
      </svg>
    </SvgIcon>
  );
};

export default CheckedIcon;
