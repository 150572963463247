import React, { useContext } from 'react';
import clsx from 'clsx';
import { AppContext } from 'context/AppContext';
import { Localisation } from 'utils';
import styles from './styles';

const ApariOfflineStatus: React.FC = () => {
  const { classes } = styles();

  const { isOnline } = useContext(AppContext);

  if (isOnline) return null;
  return (
    <div className={clsx(classes.wrapper)}>
      <div className={clsx(classes.container)}>
        <span>{Localisation.localize('SERVER_ERROR_CONNECTION_FAILED')}</span>
      </div>
    </div>
  );
};
export default ApariOfflineStatus;
