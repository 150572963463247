import axios, { AxiosResponse } from 'axios';
import { GLOBAL } from 'constants/index';
import { EntrypointValidateResponse } from 'types/apiController';

class EntryPointController {
  static async validate(entrypointId: string): Promise<AxiosResponse<EntrypointValidateResponse>> {
    return axios.get(`${GLOBAL.BASE_URL}/entrypoints/${entrypointId}/validate`);
  }
}

export default EntryPointController;
