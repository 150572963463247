import { COLORS } from '@apari-shared/constants';
import { ComponentsOverrides, Theme } from '@mui/material';

const styleOverrides: ComponentsOverrides<Theme>['MuiInputBase'] = {
  root: {
    color: COLORS.apariBlack80,
    fontSize: 13,
    borderRadius: '2px !important',
  },
};

export default styleOverrides;
