import React, { ChangeEvent } from 'react';
import { FormikContextType } from 'formik';
import InputMask from 'react-input-mask';
import { TextFieldProps } from '@mui/material';
import { ApariTextField } from 'components';

interface Props {
  control: string;
  formik: FormikContextType<any>;
}

const ApGatewayIdField: React.FC<Props & TextFieldProps> = ({
  control,
  formik,
  disabled,
  ...rest
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue(control, event.target.value);
  const inputMaskField = () => (
    <ApariTextField
      name={control}
      error={formik.touched[control] && Boolean(formik.errors[control])}
      helperText={formik.touched[control] && (formik.errors[control] as string)}
      variant="outlined"
      fullWidth
      disabled={disabled}
      margin="dense"
      {...rest}
    />
  );

  return (
    <InputMask
      mask="9999999999999"
      maskPlaceholder=""
      disabled={disabled}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      maskChar=""
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formatChars={{
        '9': '[0-9A-Za-zABCDabcd]',
        a: '[A-Za-z]',
        s: '[ABCDabcd]',
      }}
      onChange={handleChange}
      value={formik.values[control] ?? ''}
    >
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      {inputMaskField}
    </InputMask>
  );
};

export default ApGatewayIdField;
