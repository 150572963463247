import { makeStyles } from 'tss-react/mui';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'ApariOTPInput' })(theme => {
  return {
    input: {
      fontSize: 36,
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      marginRight: 6,
      marginLeft: 6,
      border: '1px solid ' + COLORS.apariGray,
      width: '65px !important',
      height: 85,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        marginRight: 3.5,
        marginLeft: 3.5,
        width: '35px !important',
        height: 50
      }
    },
    inputWrapper: {
      '& input:focus': {
        outline: 0
      }
    }
  };
});
