import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { FullScreenLoading } from 'components/index';
import { UTM_QUERY_PARAMS } from 'types';

import useQuery from 'hooks/useQuery';
import { GlobalServices, Localisation, LocalStorageServices } from 'utils';
import ScreenRouter from '../ScreenRouter';

const NotFoundScreen = lazy(() => import('containers/pages/NotFoundScreen'));

type Props = {
  authenticated?: boolean;
  acceptedTermsAndConditions?: boolean;
};

export const newPublicRoutes = ['/:entrypointId/authentication', '/'];

const AppRouter: React.FC<Props> = () => {
  const location = useLocation();

  const { queryValues } = useQuery(location, [
    UTM_QUERY_PARAMS.UTM_SOURCE,
    UTM_QUERY_PARAMS.UTM_MEDIUM,
    UTM_QUERY_PARAMS.UTM_CAMPAIGN,
    UTM_QUERY_PARAMS.UTM_TERM,
    UTM_QUERY_PARAMS.UTM_CONTENT,
    UTM_QUERY_PARAMS.GCLID,
    UTM_QUERY_PARAMS.FCLID
  ]);

  useEffect(() => {
    if (!GlobalServices.isEmpty(queryValues)) {
      LocalStorageServices.setJSON('QUERY_PARAMETERS', queryValues);
    }
  }, []);

  return (
    <AnimatePresence>
      <Suspense fallback={<FullScreenLoading />}>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                replace
                to="/authentication/error"
                state={{
                  missingEntrypointId: true,
                  message: Localisation.localize('ERROR_SCREEN_MISSING_ENTRYPOINT')
                }}
              />
            }
          />
          <Route path="/*" element={<ScreenRouter />} />
          <Route path="*" element={<NotFoundScreen />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AppRouter;
