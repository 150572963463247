import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import { GLOBAL } from 'constants/index';

class NetworkService {
  private static getTokenKey(namePrefix?: string): string {
    return namePrefix ? namePrefix + '_' + GLOBAL.JWT_COOKIE_KEY : GLOBAL.JWT_COOKIE_KEY;
  }

  static setToken(token: string, namePrefix?: string) {
    const cookie = new Cookies();
    const expireDate = new Date();
    expireDate.setMinutes(expireDate.getMinutes() + 90);
    cookie.set(this.getTokenKey(namePrefix), token, {
      path: '/',
      expires: expireDate
    });
  }

  static removeToken(namePrefix?: string) {
    const cookie = new Cookies();
    return cookie.remove(this.getTokenKey(namePrefix), { path: '/' });
  }

  static getToken(namePrefix?: string) {
    const cookie = new Cookies();
    return cookie.get(this.getTokenKey(namePrefix));
  }

  static getAuthorizationHeader(namePrefix?: string): { Authorization: string } | Record<string, never> {
    const token = this.getToken(namePrefix);
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {};
    }
  }

  static getDecodedToken(namePrefix?: string): Record<string, any> | undefined {
    const cookie = new Cookies();
    const token = cookie.get(this.getTokenKey(namePrefix));
    if (token) {
      return jwtDecode(token);
    }
  }
}

export default NetworkService;
