import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import UncheckedIcon from './UncheckedIcon';
import CheckedIcon from './CheckedIcon';

type Props = CheckboxProps & {
  primaryBorderColor?: boolean;
  invertedColors?: boolean;
};

const ApariCheckbox: React.FC<Props> = ({ checked, ...rest }) => {
  return (
    <Checkbox
      color="primary"
      icon={<UncheckedIcon />}
      checkedIcon={<CheckedIcon />}
      checked={checked || false}
      value={checked || false}
      {...rest}
    />
  );
};

export default ApariCheckbox;
