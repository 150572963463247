import React from 'react';
import clsx from 'clsx';
import { CircularProgress, Modal } from '@mui/material';
import { motion } from 'framer-motion';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

const pageVariants = {
  initial: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0.5,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.4,
};

const FullScreenLoading: React.FC = () => {
  const { classes } = styles();
  const { classes: globalClasses } = globalStyles();

  return (
    <Modal open={true} hideBackdrop={true}>
      <motion.div
        className={clsx(classes.root, globalClasses.flexColumnCenter)}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <div className={clsx(globalClasses.flexColumnCenter)}>
          <CircularProgress />
        </div>
      </motion.div>
    </Modal>
  );
};
export default FullScreenLoading;
