import React from 'react';
import { motion, MotionProps } from 'framer-motion';
import clsx from 'clsx';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

const pageVariants = {
  initial: {
    opacity: 0.7,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0.7,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.5,
};

type Props = MotionProps &
  React.HTMLAttributes<HTMLDivElement> & {
    isFixedHeader?: boolean;
    centerVerticallyInDesktop?: boolean;
    noWidthRestriction?: boolean;
    addHeight?: boolean;
  };

const AnimatedTransition: React.FC<Props> = ({
  children,
  centerVerticallyInDesktop,
  noWidthRestriction = false,
  className,
  addHeight = false,
  ...rest
}) => {
  const { classes: globalClasses } = globalStyles();
  const { classes } = styles({ noWidthRestriction, addHeight });

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className={clsx(
        classes.root,
        globalClasses.flexColumn,
        globalClasses.marginBottomAuto,
        centerVerticallyInDesktop && classes.centerContentVertically,
        className
      )}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedTransition;
