import { COLORS } from '@apari-shared/constants';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(theme => ({
  textField: {
    '& .MuiOutlinedInput-inputMarginDense': {
      padding: 23,
      color: theme.palette.text.primary,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 13,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10
      }
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      color: theme.palette.text.secondary
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 23px) scale(1)',
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('sm')]: {
        transform: 'translate(14px, 10.5px) scale(1)'
      }
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.secondary
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline':
      {
        borderColor: theme.palette.error.main
      },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& .MuiInput-underline:before': {
      borderColor: COLORS.apariBlack20
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.text.secondary
    },
    '& .MuiInputBase-root.Mui-disabled': {
      borderColor: COLORS.apariBlack20,
      color: COLORS.apariBlack20
    },
    '& .MuiInputBase-input.Mui-disabled': {
      borderColor: COLORS.apariBlack20,
      color: COLORS.apariBlack20
    }
  },
  disabledInput: {
    '& .MuiInputBase-input.Mui-disabled': {
      borderColor: COLORS.apariBlack20
    }
  },
  disableHover: {
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: COLORS.apariBlack20
    }
  },
  autofillDark: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px ' + COLORS.apariBlack20 + ' inset',
      WebkitTextFillColor: theme.palette.text.secondary + ' !important'
    }
  },
  autofill: {
    '&:-webkit-autofill': {
      transition: 'background-color 5000s ease-in-out 0s',
      WebkitTextFillColor: theme.palette.text.primary + ' !important',
      '&:disabled': {
        WebkitTextFillColor: theme.palette.text.secondary + ' !important'
      }
    },
    '&:-webkit-autofill-selected': {
      transition: 'background-color 5000s ease-in-out 0s',
      '&:disabled': {
        WebkitTextFillColor: theme.palette.text.secondary + ' !important'
      }
    }
  },
  inputLabelShrink: {
    transform: 'translate(14px, -4px) scale(0.75) !important'
  }
}));
