import { GLOBAL } from 'constants/index';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { GlobalServices, NetworkService } from 'utils';

type Event = 'SECOND_FACTOR_SELECTION_REQUIRED' | 'SECOND_FACTOR_CODE_REQUIRED' | 'LOGGED_IN' | 'ERROR' | 'FRESH';

export type WsMessage = {
  jwtToken?: string;
  eventType?: Event;
  payload?: any;
  lastStep?: Event;
  error?: string;
};

type OutgoingMessage = {
  action: 'startSession' | 'send' | 'reconnect';
  message: Record<string, any>;
};

export interface WebSocketInterface {
  lastMessage: MessageEvent<any> | null;
  readyState: ReadyState;
  handleSendMessage: (message: OutgoingMessage) => void;
  wsLoading: boolean;
  setWsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reconnectionLoading: boolean;
  setReconnectionLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WebSocketContext = React.createContext({} as WebSocketInterface);

export const WebSocketProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [wsLoading, setWsLoading] = useState(false);
  const [reconnectionLoading, setReconnectionLoading] = useState(true);

  const handleSendMessage = useCallback((message: OutgoingMessage) => sendMessage(JSON.stringify(message)), []);

  const entrypointIdFromPath = window.location.pathname.split('/')[1];
  const entrypointId = GlobalServices.isValidEntrypointIdFormat(entrypointIdFromPath);

  const { sendMessage, lastMessage, readyState } = useWebSocket(GLOBAL.REACT_APP_WEBSOCKET_URL, {
    onOpen: () => {
      const jwtToken = NetworkService.getToken(entrypointId);

      if (jwtToken) {
        handleSendMessage({ action: 'reconnect', message: { jwtToken } });
      } else {
        setReconnectionLoading(false);
        handleSendMessage({ action: 'startSession', message: { entrypointId } });
      }

      console.log('WebSocket connected');
    },
    onMessage: lastMessage => {
      const message: WsMessage = JSON.parse(lastMessage.data);

      if (message.jwtToken && NetworkService.getToken(entrypointId) === undefined) {
        NetworkService.setToken(message.jwtToken, entrypointId);
      }
    },
    onClose: () => {
      console.log('WebSocket disconnected');
    }
  });

  return (
    <WebSocketContext.Provider
      value={{
        lastMessage,
        readyState,
        handleSendMessage,
        wsLoading,
        setWsLoading,
        reconnectionLoading,
        setReconnectionLoading
      }}
    >
      <React.Fragment>{children}</React.Fragment>
    </WebSocketContext.Provider>
  );
};
