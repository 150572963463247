import React from 'react';
import { SvgIcon } from '@mui/material';

const UncheckedIcon: React.FC = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1" fill="white">
          <rect width="24" height="24" rx="1" />
        </mask>
        <rect
          width="24"
          height="24"
          rx="1"
          stroke="black"
          strokeOpacity="0.8"
          strokeWidth="4"
          mask="url(#path-1-inside-1)"
        />
      </svg>
    </SvgIcon>
  );
};

export default UncheckedIcon;
