import { TFA_MESSAGES_DEBUG, TFA_METHODS_DEBUG, VALID_THEME_KEYS } from 'constants/debugModeData';
import { GLOBAL } from 'constants/index';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mode, NotificationTypes, ScreenRoutes, TFAMethods } from 'types';
import { Localisation, NetworkService } from 'utils';
import { EntryPointController } from 'utils/apiControllers';
import { Choice } from '../types/apiController';
import { AppContext } from './AppContext';
import { CompanyThemeContext } from './CompanyThemeContext';
import { WebSocketContext, WsMessage } from './WebSocketContext';

interface GovAuthenticationContextInterface {
  wsLoading: boolean;
  setWsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tfaMethods?: TFAMethods;
  setTfaMethods: React.Dispatch<React.SetStateAction<TFAMethods | undefined>>;
  selectedMethod?: string;
  setSelectedMethod: React.Dispatch<React.SetStateAction<string | undefined>>;
  tfaCodeMessage?: string[];
  setTfaCodeMessage: React.Dispatch<React.SetStateAction<string[]>>;
  introductionActiveStep: number;
  setIntroductionActiveStep: React.Dispatch<React.SetStateAction<number>>;
  tfaCode?: string;
  setTfaCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleLoginDebug: (gatewayId: string) => void;
  handleMethodChoiceDebug: () => void;
  handleCodeSubmitDebug: (code: string) => void;
  setEntrypointId: React.Dispatch<React.SetStateAction<string | undefined>>;
  metadata: Record<string, string>;
  setMetadata: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  entrypointId?: string;
  validateLoading: boolean;
  invalidateToken: () => void;
  isDebugMode: () => boolean;
}

export const GovAuthenticationContext = React.createContext({} as GovAuthenticationContextInterface);

export const GovAuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [validateLoading, setValidateLoading] = useState(false);
  const [metadata, setMetadata] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [tfaMethods, setTfaMethods] = useState<TFAMethods | undefined>();
  const [selectedMethod, setSelectedMethod] = useState<string>();
  const [tfaCodeMessage, setTfaCodeMessage] = useState<string[]>([]);
  const [tfaCode, setTfaCode] = useState<string>();
  const [entrypointId, setEntrypointId] = useState<string>();
  const [mode, setMode] = useState<Mode>();
  const [introductionActiveStep, setIntroductionActiveStep] = useState(0);

  const { showLoadingBar, hideLoadingBar, showNotifications } = useContext(AppContext);
  const { setCompanyTheme, setLoadingCompanyTheme } = useContext(CompanyThemeContext);
  const { lastMessage, wsLoading, setWsLoading, setReconnectionLoading } = useContext(WebSocketContext);

  const navigate = useNavigate();

  window.addEventListener(
    'message',
    e => {
      if (e) {
        if (
          Object.keys(e.data).every(key => VALID_THEME_KEYS.includes(key)) &&
          (GLOBAL.DISABLE_ORIGIN_CHECK_IFRAME === 'true' ? true : e.origin.split('.')[1] === window.location.host.split('.')[1]) &&
          isDebugMode()
        ) {
          setCompanyTheme(e.data);
        } else {
          return;
        }
      }
    },
    false
  );

  useEffect(() => {
    if (lastMessage) {
      const message: WsMessage = JSON.parse(lastMessage.data);
      handleWsMessage(message);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (mode) {
      if (entrypointId) {
        if (!isDebugMode()) {
          handleEntrypointValidation(entrypointId);
        }
      } else {
        setLoadingCompanyTheme(false);
        setValidateLoading(false);
      }
    }
  }, [mode, entrypointId]);

  useEffect(() => {
    if (entrypointId) {
      if (entrypointId === 'debug-mode') {
        setMode(Mode.DEBUG);
        setLoadingCompanyTheme(false);
      } else {
        setMode(Mode.PRODUCTION);
      }
    }
  }, [entrypointId]);

  const isDebugMode = () => mode === Mode.DEBUG;

  const handleEntrypointValidation = async (entrypointId: string) => {
    setValidateLoading(true);
    setLoadingCompanyTheme(true);
    try {
      const response = await EntryPointController.validate(entrypointId);
      setCompanyTheme(response.data.theme?.settings ?? undefined);
      if (!response.data.theme) {
        setLoadingCompanyTheme(false);
      }
      if (response.data.error) {
        navigate(ScreenRoutes.ERROR, {
          replace: true,
          state: {
            message: response.data.error.message,
            invalidEntrypoint: true
          }
        });
      }
    } catch (e: any) {
      console.log('e', e);
      setLoadingCompanyTheme(false);
      if (e.error !== 'Theme not found') {
        navigate(entrypointId ? entrypointId + ScreenRoutes.ERROR : ScreenRoutes.ERROR, {
          replace: true,
          state: {
            parameter: entrypointId,
            message: Localisation.localize('ERROR_SCREEN_PROBLEM_WITH_VALIDATION')
          }
        });
      }
    }
    setValidateLoading(false);
  };

  const handleWsMessage = (message: WsMessage) => {
    let preparedTfaMethods = {};

    if (message.error && !isDebugMode()) {
      navigate(ScreenRoutes.ERROR, {
        replace: true,
        state: {
          message: message.error,
          invalidEntrypoint: true
        }
      });
    }

    switch (message.eventType || message.lastStep) {
      case 'FRESH':
        setWsLoading(false);
        setLoading(false);
        setReconnectionLoading(false);
        hideLoadingBar();
        break;
      case 'SECOND_FACTOR_SELECTION_REQUIRED':
        setSelectedMethod(undefined);
        if (message.payload?.choices) {
          message.payload.choices.forEach(
            (choice: Choice) =>
              (preparedTfaMethods = {
                ...preparedTfaMethods,
                [choice.id!]: choice.text
              })
          );
        }
        setTfaMethods(preparedTfaMethods);
        navigate('/' + entrypointId + ScreenRoutes.CHOSE_METHOD);
        setWsLoading(false);
        setLoading(false);
        setReconnectionLoading(false);
        hideLoadingBar();
        break;
      case 'SECOND_FACTOR_CODE_REQUIRED':
        setTfaCodeMessage(message.payload?.choices);
        setTfaCode(undefined);
        navigate('/' + entrypointId + ScreenRoutes.ENTER_CODE);
        setWsLoading(false);
        setLoading(false);
        setReconnectionLoading(false);
        hideLoadingBar();
        break;
      case 'LOGGED_IN':
        navigate('/' + entrypointId + ScreenRoutes.SUCCESS, { replace: true });
        setTfaCodeMessage([]);
        setTfaMethods(undefined);
        setSelectedMethod(undefined);
        setTfaCode(undefined);
        setWsLoading(false);
        setLoading(false);
        setReconnectionLoading(false);
        hideLoadingBar();
        break;
      case 'ERROR':
        setTfaCode(undefined);
        setWsLoading(false);
        setLoading(false);
        setReconnectionLoading(false);
        hideLoadingBar();
        if (message.payload && message.payload.errors && message.payload.errors.length) {
          let messages = '';
          const len = message.payload.errors.length;
          let i = 0;
          while (i < len) {
            messages = messages + (i > 0 ? ', ' : '') + message.payload.errors[i];
            i++;
          }
          if (messages.startsWith('2FA not configured!')) {
            navigate(ScreenRoutes.ERROR, {
              replace: true,
              state: {
                message: Localisation.localize('ERROR_SCREEN_MISSING_2FA'),
                invalidEntrypoint: false,
                missing2FA: true,
                parameter: entrypointId
              }
            });
          }
          showNotifications({
            type: NotificationTypes.INFO,
            title: 'Login failed',
            message: messages
          });
        } else {
          navigate(ScreenRoutes.ERROR, {
            replace: true,
            state: { parameter: entrypointId }
          });
        }
        break;
    }
  };

  const handleLoginDebug = (gatewayId: string) => {
    setLoading(true);
    showLoadingBar();
    setTimeout(() => {
      hideLoadingBar();
      setLoading(false);
      setWsLoading(true);
    }, 1000);

    setTimeout(() => {
      if (gatewayId === 'USERwrong') {
        setWsLoading(false);
        showNotifications({
          type: NotificationTypes.INFO,
          title: 'Login failed',
          message: 'Wrong credentials'
        });
      } else {
        setSelectedMethod(undefined);
        setTfaMethods(TFA_METHODS_DEBUG);
        navigate('/' + entrypointId + ScreenRoutes.CHOSE_METHOD);
        setWsLoading(false);
      }
    }, 3000);
  };

  const handleMethodChoiceDebug = () => {
    setLoading(true);
    showLoadingBar();
    setTimeout(() => {
      hideLoadingBar();
      setLoading(false);
      setWsLoading(true);
    }, 1000);
    setTimeout(() => {
      setTfaCodeMessage(TFA_MESSAGES_DEBUG);
      setTfaCode(undefined);
      navigate('/' + entrypointId + ScreenRoutes.ENTER_CODE);
      setWsLoading(false);
    }, 3000);
  };

  const handleCodeSubmitDebug = (code: string) => {
    setLoading(true);
    showLoadingBar();
    setTimeout(() => {
      hideLoadingBar();
      setLoading(false);
      setWsLoading(true);
    }, 1000);
    setTimeout(() => {
      if (code === '666999') {
        navigate('/' + entrypointId + ScreenRoutes.SUCCESS, { replace: true });
        setTfaCodeMessage([]);
        setTfaMethods(undefined);
        setSelectedMethod(undefined);
        setTfaCode(undefined);
        setWsLoading(false);
      } else {
        setTfaCode(undefined);
        setWsLoading(false);

        if (code === '666998') {
          showNotifications({
            type: NotificationTypes.INFO,
            title: 'Invalid code',
            message: 'Wrong code'
          });
        } else {
          navigate(ScreenRoutes.ERROR, {
            replace: true,
            state: { parameter: entrypointId }
          });
        }
      }
    }, 3000);
  };

  const invalidateToken = () => {
    NetworkService.removeToken(entrypointId);
  };

  return (
    <GovAuthenticationContext.Provider
      value={{
        wsLoading,
        setWsLoading,
        loading,
        setLoading,
        tfaMethods,
        setTfaMethods,
        selectedMethod,
        setSelectedMethod,
        tfaCodeMessage,
        setTfaCodeMessage,
        tfaCode,
        introductionActiveStep,
        setIntroductionActiveStep,
        setTfaCode,
        handleLoginDebug,
        handleMethodChoiceDebug,
        handleCodeSubmitDebug,
        setEntrypointId,
        entrypointId,
        validateLoading,
        invalidateToken,
        isDebugMode,
        metadata,
        setMetadata
      }}
    >
      {children}
    </GovAuthenticationContext.Provider>
  );
};
