import English from 'utils/localisation/I18n/languages/en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: English,
  },
};

const currentLanguage = localStorage.getItem('currentLanguageCode') || 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: currentLanguage,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});

export default i18n;
