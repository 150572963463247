import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'AnimatedStepWrapper' })(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      overflowX: 'hidden'
    }
  }
}));
