import { COLORS } from '@apari-shared/constants';
import { ComponentsOverrides, Theme } from '@mui/material';

const styleOverrides: ComponentsOverrides<Theme>['MuiIconButton'] = {
  root: {
    color: COLORS.apariBlack80,
  },
};

export default styleOverrides;
