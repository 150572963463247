import { COLORS } from '@apari-shared/constants';
import { ComponentsOverrides, Theme } from '@mui/material';

const styleOverrides: ComponentsOverrides<Theme>['MuiFab'] = {
  root: {
    width: 46,
    height: 46,
    border: '2px solid ' + COLORS.transparentLight,
  },
};

export default styleOverrides;
