import React from 'react';
import clsx from 'clsx';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { ApariCheckbox } from 'components';
import styles from './styles';

interface Props {
  choice: string;
  className?: string;
  onCheckboxChange?: (choice: string) => void;
  title: string;
  checked?: boolean | undefined;
  disabled?: boolean;
}

const MethodCard: React.FC<Props> = ({
  choice,
  title,
  onCheckboxChange,
  checked,
  className,
  disabled,
  ...rest
}) => {
  const { classes } = styles();

  const checkboxChange = (event: any, choice: string) => {
    event.stopPropagation();
    onCheckboxChange && onCheckboxChange(choice);
  };

  return (
    <div className={clsx(classes.rowCard, className)} {...rest}>
      <div className={clsx(classes.cardContent)}>
        <div className={clsx(classes.rowCardLeft)}>
          {onCheckboxChange && (
            <ApariCheckbox
              icon={<CircleUnchecked style={{ borderColor: 'black' }} />}
              checkedIcon={
                <CircleCheckedFilled style={{ borderColor: 'black' }} />
              }
              value={false}
              checked={checked}
              disabled={disabled}
              onClick={(event: any) => {
                checkboxChange(event, choice);
              }}
              inputProps={{ id: `tfaChoice-${choice}` }}
            />
          )}

          <div className={clsx(classes.rowCardInfo)}>
            <div className={clsx(classes.rowCardInfoSender)}>
              <strong className={clsx(classes.rowCardInfoSenderTitle)}>
                {title || '-'}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MethodCard;
