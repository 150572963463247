import React, { ReactElement, useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { IconButton, Modal, Typography, useTheme } from '@mui/material';
import { AppContext } from 'context/AppContext';
import { CircleCloseIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import { Localisation } from 'utils';
import styles from './styles';
import ExpandableItem from './ExpandableItem';

const pageVariants = {
  initial: {
    opacity: 0,
    transform: 'scale(0.9)'
  },
  in: {
    opacity: 1,
    transform: 'scale(1)'
  },
  out: {
    opacity: 0.7
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.4
};

type HelpItem = {
  id: string;
  title: string;
  content: ReactElement;
};

const HelpCenterDialog: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>();

  const { classes } = styles();
  const { classes: globalClasses } = globalStyles();

  const theme = useTheme();

  const { openedHelpCenter, setOpenedHelpCenter, leaveApp } = useContext(AppContext);

  const HELP_ITEMS: HelpItem[] = useMemo(() => {
    const array: HelpItem[] = [
      {
        id: 'hp-1',
        title: 'What is a Government Gateway ID?',
        content: (
          <>
            <p>
              {`This is the ID you use to access many online services from the UK Government, including Self Assessment tax returns. \n\nIt’s usually 12 digits long.`}
            </p>
          </>
        )
      },
      {
        id: 'hp-2',
        title: 'Why do I need to use a Government Gateway ID?',
        content: (
          <>
            <p>
              {`Our digital income verification service saves you time and effort with your application. \n\nYour Government Gateway ID is used to retrieve your income information securely.`}
            </p>
          </>
        )
      },
      {
        id: 'hp-3',
        title: 'What happens to my data?',
        content: (
          <>
            <p>{`Your income information will only be used for the purpose of this application process. \n\nOnce the information has been retrieved, we will securely terminate the connection to HMRC. \n\nYour Government Gateway credentials are not stored. If you use this service again in the future, you will need to enter them again.`}</p>
          </>
        )
      },
      {
        id: 'hp-4',
        title: 'How do I know if I have a Government Gateway ID?',
        content: (
          <>
            <p>{`If you regularly use HMRC digital services, your ID may be saved in your browser or on your device. \n\nAlternatively, your ID may be included in an email. Search your inbox and other folders for ‘Government Gateway user ID’.`}</p>
          </>
        )
      },
      {
        id: 'hp-5',
        title: 'What if I have forgotten my ID or password?',
        content: (
          <>
            <p>{`There is a straightforward process for recovering either your ID or your password. If you’ve forgotten both, it’s a bit more complicated. \n\nFollow the instructions to sign in to your ‘Personal Tax Account’ via the link below.`}</p>
            {`\n`}
            <span
              className={clsx(classes.link)}
              onClick={() => {
                leaveApp('https://www.gov.uk/log-in-register-hmrc-online-services/problems-signing-in');
                setTimeout(() => {
                  setOpenedHelpCenter(false);
                  setActiveItem(undefined);
                }, 1000);
              }}
            >
                            HMRC: Problems signing in
                        </span>
          </>
        )
      },
      {
        id: 'hp-6',
        title: 'How can I get a Government Gateway ID?',
        content: (
          <>
            <p>{`If you need to create an ID, you can do this online via the Government website. \n\nFollow the instructions to set up a ‘Personal Tax Account’ via the link below.`}</p>
            {`\n`}
            <span
              className={clsx(classes.link)}
              onClick={() => {
                leaveApp('https://www.gov.uk/personal-tax-account');
                setTimeout(() => {
                  setOpenedHelpCenter(false);
                  setActiveItem(undefined);
                }, 1000);
              }}
            >
                            HMRC: Personal Tax Account
                        </span>
          </>
        )
      }
    ];
    if (activeItem) {
      return [...array.filter(x => x.id === activeItem), ...array.filter(x => x.id !== activeItem)];
    } else {
      return array;
    }
  }, [classes, activeItem]);

  const onExpand = (itemId: string, expanded: boolean) => {
    if (expanded) {
      setActiveItem(itemId);
    } else {
      setActiveItem(undefined);
    }
  };

  const onModalClose = () => {
    setOpenedHelpCenter(false);
    setTimeout(() => {
      setActiveItem(undefined);
    }, 500);
  };

  return (
    <Modal open={openedHelpCenter} hideBackdrop={true} onClose={onModalClose}>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className={clsx(classes.root, globalClasses.flexColumn)}
      >
        <div className={clsx(globalClasses.flexRowEnd, globalClasses.padding20)}>
          <IconButton color="primary" onClick={onModalClose} className={clsx(globalClasses.noPadding)}>
            <CircleCloseIcon
              primaryColor={theme.palette.primary.main}
              fillColor={theme.palette.background.default}
              style={{ fontSize: 28 }}
            />
          </IconButton>
        </div>
        <div className={clsx(globalClasses.flexColumnCenter)}>
          <Typography color="textPrimary" className={clsx(classes.smallTitle)}>
            {Localisation.localize(`HELP_CENTER_TITLE`)}
          </Typography>
          {HELP_ITEMS.map(helpItem => (
            <ExpandableItem
              key={helpItem.id}
              id={helpItem.id}
              title={helpItem.title}
              expanded={activeItem === helpItem.id}
              handleExpansion={onExpand}
            >
              {helpItem.content}
            </ExpandableItem>
          ))}
        </div>
      </motion.div>
    </Modal>
  );
};
export default HelpCenterDialog;
